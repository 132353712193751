@use '@angular/material' as mat;
@use 'theme-colors' as sovityColors;
@use 'theme-colors.mds' as mdsColors;
@use 'theme-generated-variables' as themeGeneratedVars;

// Import styles that aren't theme dependant (including typography)
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

$custom-typography: mat.define-typography-config(
  $font-family: 'Open Sans',
);
@include mat.core($custom-typography);

// Import theme and theme-dependant styles
.theme-sovity {
  @include mat.all-component-themes(sovityColors.$theme);
  @include themeGeneratedVars.theme-vars(
    sovityColors.$theme,
    sovityColors.$link-color
  );
}

.theme-mds {
  @include mat.all-component-themes(mdsColors.$theme);
  @include themeGeneratedVars.theme-vars(
    mdsColors.$theme,
    mdsColors.$link-color
  );
}

// fix paginator background, it was white before, but our background is gray
mat-paginator {
  background: transparent !important;
}

// Fix Icon sizes used in card avatars
mat-icon[mat-card-avatar],
.mat-card-avatar-icon {
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  margin-top: 1px;
  margin-right: -3px;
  margin-left: 2px;
}

// Fix mat-chips exploding out and/or having multiline exploding strings
mat-chip {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Fix Date range second-date-text being "higher" than first-date-text
.mat-date-range-input-container {
  align-items: unset !important;
}
